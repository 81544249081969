

































import { Component, Vue, PropSync } from "vue-property-decorator";
import VersionBackModule from "@/store/modules/VersionBack-module";
import VersionChecker from "@/shared/services/version-checker";
@Component({
  components: {},
})
export default class NewVersion extends Vue {
  @PropSync("dialog") dialogSync!: boolean;
  public htmls: string = "";
  public created() {
    VersionBackModule.getVersionFrontData();
  }
  public async version() {
    return await VersionChecker.Version();
  }
  public get dataVersion() {
    try {
      return VersionBackModule.VersionFrontData.descripcion;
    } catch (error) {
      return "";
    }
  }
}
